.lang-modal {
  background-color: #151515 !important;
  color: #fff;
}

.offcanvas-header .btn-close {
  color: #fff;
}

.offcanvas-top {
  height: 19vh;
  max-height: 30vh;
}

.offcanvas-body {
  display: flex;
  flex-wrap: wrap;
}
