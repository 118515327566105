.hamburger {
  display: flex;
  color: #fff;
  font-size: 25px;
  background: none;
  border: none;
}

.toggle-icon {
  transition: opacity 1s;
}

.navbar-brand > img {
  max-width: 170px;
}

.navbar {
  background-color: #151515;
  z-index: 500;
  height: 100%;
  align-content: center;
}

@media only screen and (min-width: 480px) and (max-width: 824px) {
  .navbar {
    max-height: 50px;
  }

  .navbar-brand > img {
    max-width: 100px;
  }
}

@media only screen and (min-width: 768px) {
  .navbar {
    height: 90px;
  }
  .navbar-brand > img {
    max-width: 150px;
  }
}
