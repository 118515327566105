.video-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.video-container > iframe {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 83%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.video-container > video {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.control-info > h6 {
  text-align: center;
}

.control-info > p {
  text-align: center;
}

.control-info {
  border-radius: 10px 10px 10px 10px;
  background-color: #1515158e;
  display: flex;
  position: relative;
  margin: 0;
  margin-top: 400px;
  padding: 25px;
  flex-direction: column;
  align-items: center;
  color: #fff;
  overflow: initial;
}

.control-info > h3 {
  font-weight: 800;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.close-tooltip {
  position: absolute;
  top: -4px;
  left: -4px;
  cursor: pointer;
  font-size: 18px;
}

.tooltip-toggle {
  position: absolute;
  bottom: 100px;
  right: 50px;
  cursor: pointer;
  font-size: 35px;
  color: #fff;
}

.time {
  font-size: 1.4rem;
  font-weight: 700;
}

.interval {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 200;
}

.social-share {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 10px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.social-share > button {
  margin-bottom: 5px;
  opacity: 0.8;
  transition: all 0.5s;
}

.social-share > button:hover {
  transform: scale(1.1);
  opacity: 1;
}

.center-text {
  text-align: center;
  margin: 0;
}

/* mobil */
@media only screen and (min-width: 320px) {
  .control-info {
    margin-top: 200px;
    max-width: 80vw;
  }

  .control-info > h3 {
    font-weight: 600;
    font-size: 1.2rem;
  }

  .interval {
    font-size: 1.1rem;
    text-align: left;
  }

  .tooltip-toggle {
    bottom: 130px;
    right: 50px;
  }
  .center-text {
    text-align: left;
    margin: 0;
  }
}

/* mezi mobilem a tabletem */

@media only screen and (min-width: 480px) {
}

/* Tablet */

@media only screen and (min-width: 768px) {
  .control-info {
    margin-top: 300px;
  }
  .center-text {
    text-align: center;
  }

  .social-share {
    top: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .control-info > h3 {
    font-weight: 800;
    font-size: 1.8rem;
    text-transform: uppercase;
  }
  .center-text {
    text-align: center;
  }
}
