.offcanvas-body .img-thumbnail {
  background-color: #151515;
  border: 1px solid #272727;
  max-width: 100px;
  max-height: 50px;
  margin: 4px;
}

.img-thumbnail:hover {
  transition: 0.5s;
  transform: scale(1.05);
}
