* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
}

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}
body {
  position: relative;
}

a {
  text-decoration: none;
}

.hamburger {
  cursor: pointer;
}

.main-content {
  display: flex;
  flex-direction: row;
}

.sidebar {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  background-color: #1d1d1d;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  width: 500px;
  left: -500px;
  padding: 20px;
  z-index: 1000;
}

.video-item {
  background-color: #272727;
  display: flex;
  width: 100%;
  min-width: 100%;
  height: 100px;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.video-item:hover {
  background-color: #3a3a3ade;
}

.myBut {
  margin-bottom: 20px;
}

.video-item img {
  max-height: 100%;
}

.video-item img:hover {
  transition: 2s;
  transform: scale(1.1);
}

.video-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 15px;
}

.video-title > h4 {
  font-size: 1.3rem;
  font-weight: 800;
}

.video-title > h6 {
  font-weight: 400;
}

.myBut {
  background-color: #a9d067;
  margin-top: 5px;
  padding: 10px;
  border: none;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 500;
}

.myBut:hover {
  background-color: #84bc26;
}

/* Sidebar scroll bar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* mobil */
@media only screen and (max-width: 600px) {
  .sidebar {
    width: 100%;
  }

  .offcanvas-top {
    height: 100vh !important;
  }

  .video-thumbnail {
    float: left;
  }

  .video-title > h4 {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .video-title > h6 {
    font-weight: 400;
    margin-bottom: 0;
  }

  .video-item {
    height: 70px;
  }
}
